<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="regionId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('所属区域')}}<template slot="title">{{$t('所属区域')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.regionId"  :placeholder="$t('通用.输入.请输入')+$t('所属区域')" />
      </a-form-model-item>
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('监控名称')}}<template slot="title">{{$t('监控名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('监控名称')" />
      </a-form-model-item>
      <a-form-model-item prop="code" >
        <span slot="label" >
          <a-tooltip>
            {{$t('监控编码')}}<template slot="title">{{$t('监控编码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.code" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('监控编码')" />
      </a-form-model-item>
      <a-form-model-item prop="monitorType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('监控类型 1：萤石')}}<template slot="title">{{$t('监控类型 1：萤石')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="region" >
        <span slot="label" >
          <a-tooltip>
            {{$t('所属区域')}}<template slot="title">{{$t('所属区域')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.region" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('所属区域')" />
      </a-form-model-item>

      <a-form-model-item prop="bindingId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('绑定主键(船舶/港口)')}}<template slot="title">{{$t('绑定主键(船舶/港口)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.bindingId"  :placeholder="$t('通用.输入.请输入')+$t('绑定主键(船舶/港口)')" />
      </a-form-model-item>
      <a-form-model-item prop="bindingType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('分组类型 0：无意义 1：船舶 2：港口 3：标记')}}<template slot="title">{{$t('分组类型 0：无意义 1：船舶 2：港口 3：标记')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="deviceSerial" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备序列号')}}<template slot="title">{{$t('设备序列号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceSerial" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('设备序列号')" />
      </a-form-model-item>
      <a-form-model-item prop="cameraNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('通道号')}}<template slot="title">{{$t('通道号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.cameraNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('通道号')" />
      </a-form-model-item>
      <a-form-model-item prop="verifyCode" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备加密密码')}}<template slot="title">{{$t('设备加密密码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.verifyCode" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('设备加密密码')" />
      </a-form-model-item>


      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMonitor, addMonitor, updateMonitor } from '@/api/device/monitor/monitor'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        regionId: null,

        id: null,

        name: null,

        code: null,

        monitorType: null,

        region: null,

        monitorStatus: 0,

        createTime: null,

        remark: null,

        bindingId: null,

        bindingType: null,

        deviceSerial: null,

        cameraNo: null,

        verifyCode: null,

        token: null,

        expireTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        monitorStatus: [
          { required: true, message: this.$t('状态 1：绑定 2：未绑定')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        regionId: null,
        id: null,
        name: null,
        code: null,
        monitorType: null,
        region: null,
        monitorStatus: 0,
        createTime: null,
        remark: null,
        bindingId: null,
        bindingType: null,
        deviceSerial: null,
        cameraNo: null,
        verifyCode: null,
        token: null,
        expireTime: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMonitor({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateMonitor(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addMonitor(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
