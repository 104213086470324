import request from '@/utils/request'


// 查询监控信息列表
export function listMonitor(query) {
  return request({
    url: '/monitor/monitor/list',
    method: 'get',
    params: query
  })
}

// 查询监控信息分页
export function pageMonitor(query) {
  return request({
    url: '/monitor/monitor/page',
    method: 'get',
    params: query
  })
}

// 查询监控信息详细
export function getMonitor(data) {
  return request({
    url: '/monitor/monitor/detail',
    method: 'get',
    params: data
  })
}

// 新增监控信息
export function addMonitor(data) {
  return request({
    url: '/monitor/monitor/add',
    method: 'post',
    data: data
  })
}

// 修改监控信息
export function updateMonitor(data) {
  return request({
    url: '/monitor/monitor/edit',
    method: 'post',
    data: data
  })
}

// 删除监控信息
export function delMonitor(data) {
  return request({
    url: '/monitor/monitor/delete',
    method: 'post',
    data: data
  })
}
